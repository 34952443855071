import React from "react";

const Footer = () => {
  // const d = new Date();
  return (
    <></>
    // <div className="footer">
    //   <div className="copyright p-2">
    //     <p>
    //       Copyright © 
    //       {d.getFullYear()}
    //     </p>
    //   </div> 
    // </div>
  );
};

export default Footer;
