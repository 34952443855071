import BaseService from "./BaseService";
import { API_BASE_URL_ENV } from "../jsx/common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/setting/currency";

export default class CurrencyService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

}
