export const Rules = [
  { label: "Admin", value: "admin" },
  { label: "Products", value: "products" },
  { label: "Users", value: "users" },
  { label: "Order", value: "order" },
  { label: "Promo Code", value: "promo_code" },
  { label: "Categories", value: "categories" },
  { label: "SubCategories", value: "sub_categories" },
  { label: "Rules", value: "rules" },
  { label: "Variant", value: "variant" },
  { label: "Banners", value: "banners" },
  { label: "Ad Screen", value: "ad_screen" },
  { label: "Social Media", value: "social_media" },
  { label: "Brands", value: "brands" },
  { label: "Control", value: "control" },
  { label: "Currency", value: "currency" },
  { label: "Notification", value: "notification" },
  { label: "TimeSlot", value: "time_slot" },
  { label: "Static Pages", value: "static_pages" },
  { label: "Payment Link", value: "payment_link" },
  //   { label: "Tranaction Status", value: "tranaction_status" },
  { label: "Delivery", value: "delivery" },
  { label: "Create an Invoice", value: "create_an_invoice" },
];
